.add-elements {
  .form-select-button {
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;
    .primary-button {
      width: 25% !important;
    }
  }
  .form-selected-elements {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    .element-added {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .image-text-wrapper {
        display: flex;
        align-items: center;
        column-gap: 1rem;
      }
      .delete-icon {
        cursor: pointer;
      }
    }
    margin-bottom: 1rem;
  }
  .add-element-input {
    width: 100%;
    .slide-toggle-wrapper {
      margin-top: 0.5rem;
    }
  }
}
