.form-tasks {
  .add-element-input {
    width: 100%;
    .form-select-button {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      .primary-button {
        width: 25% !important;
      }
    }
  }
  .form-selected-elements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: auto;
    max-height: 5rem;
  }
  .disclaimer {
    width: 100%;
    display: flex;
    .label-div {
      padding: 1rem;
      display: flex;
      gap: 0.5rem;
      width: 100%;
    }
  }
  .files-attached {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 5rem;
    overflow-y: auto;
  }
  .form-comments {
    margin-top: 1rem;
  }
  .comments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 8rem;
    min-height: 4rem;
    overflow-y: auto;
  }
}
